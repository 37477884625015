.MuiPhoneNumber-positionStart::after{
    padding-left: 4px;
    padding-top: 6px;
  }
  
  .chevronDown .MuiPhoneNumber-positionStart::after{  
    content:url("../../../../public/arrow_drop_down.svg");
  }
  
  .chevronUp .MuiPhoneNumber-positionStart::after{  
    content:url("../../../../public/arrow_drop_up.svg");
  }